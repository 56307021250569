import React from 'react';
import Layout from '../components/Layout.js';
import Head from '../components/head';
import { StaticQuery, graphql } from "gatsby"
import IBooksBadge from "../images/iBooksBadge.svg";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$/));

class LlanoExpand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {mainImage: images["LlanoShowcase01.jpg"]}
    this.changeImage = this.changeImage.bind(this)
  }

  changeImage(e) {
    console.log(e.target.src)
    this.setState({mainImage: e.target.src})
  }

  render() {
    return (
      <Layout>
      <Head title="El Gran Llano" />
      <div className="showcase-container">
        <div className="main-image-container">
          <img src={this.state.mainImage} />
        </div>
        <div className="bottom-images-container">
          <img onClick={this.changeImage} src={images["LlanoShowcase01.jpg"]} />
          <img onClick={this.changeImage} src={images["LlanoShowcase02.jpg"]} />
          <img onClick={this.changeImage} src={images["LlanoShowcase03.jpg"]} />
          <img onClick={this.changeImage} src={images["LlanoShowcase04.jpg"]} />
          <img onClick={this.changeImage} src={images["LlanoShowcase05.jpg"]} />
        </div>
      </div>
      <h2 className="tituloDeLibroFullScreen">El Gran Llano</h2>
        <section className="wideExpandTextoSection">
        <p className="section-text">¡Nuestro primer libro desarrollado internamente! Acompaña a Becerrito en este paseo interactivo y narrado por El Gran Llano. Donde descubrirás con él a todos su habitantes y actividades.
Escucha los sonidos de los animales, y descubre qué los hace especiales en esta peculiar granja.
Historia original escrita por Ana Coronado e ilustrado en acuarela por Paulina Coronado. Lee a tu ritmo, o escucha la narración de Alejandra Gollás.
        </p>
      </section>

      <div className="logo-itunes-container">
        <a href="https://itunes.apple.com/mx/book/el-gran-llano/id1071272787"><img src={IBooksBadge} /></a>
      </div>
      </Layout>
    );
  }
}

export default LlanoExpand;
